
.header__header___1ObzF{margin-bottom:50px;margin-top:10px
}
.header__header__in___2VqBI{align-items:flex-end;display:flex;justify-content:space-between
}
.header__header__profile___2P_xl{background:#1784cd;color:#fff;padding:30px 50px;position:relative;width:24%
}
.header__header__profile-exit___7EVqB{font-size:.8em;position:absolute;right:10px;top:10px
}
.header__header__profile-exit___7EVqB:hover{text-shadow:0 0 3px #fff
}
.header__header__profile-name___1X4jC{display:block;font-weight:700;margin-bottom:5px
}
.header__header__profile-name___1X4jC:hover{text-decoration:underline
}
.header__header__profile-info___3w-Pf{font-size:.8em;margin-bottom:2px
}
.header__header__col___1bxMz{border-top:10px solid #34aadc;padding:10px 20px;text-align:center;width:18%
}
.header__header__col-name___11mBf{color:#34aadc;font-size:1.2em;font-weight:700
}
.header__header__col-name___11mBf:hover{text-decoration:underline
}
.header__header__col-desc___2Mg7m{font-size:.8em;margin-top:3px;opacity:.5
}
.header__header__col-num___1URG6{background-color:#34aadc;border-radius:3px;color:#fff;display:block;font-size:.8em;margin-top:5px;padding:3px 5px
}
.header__header__col-num___1URG6:hover{opacity:.8
}