
.profile__form___GWoAJ{align-items:stretch;display:flex;justify-content:stretch
}
.profile__form__field___3pNd0{display:inline-block;margin-right:10px
}
.profile__form__button___7AbBR{padding:0 20px
}
.profile__changebox___3nuIF{background:#d3d3d3;border-radius:3px;box-sizing:border-box;color:#fff;display:inline-block;margin:0 .5%;padding:20px 20px 20px 25px;position:relative;text-align:right;width:15%
}
.profile__changebox___3nuIF:hover{background:#34aadc;opacity:.8
}
.profile__changebox--active___7U5F8{background:#34aadc;box-shadow:0 0 4px rgba(0,0,0,.5)
}
.profile__changebox__name___L8Zry{font-size:1.1em;font-weight:700;margin-bottom:10px
}
.profile__changebox__input___3tBXe{position:absolute;visibility:hidden
}
.profile__changebox__icon___wXL_7{box-sizing:border-box;display:inline-block;font-size:.8em;font-style:normal;left:10px;position:absolute;top:7px;transition:all .3s
}
.profile__changebox__input___3tBXe:checked~.profile__changebox__icon___wXL_7{background:#1784cd
}