
.header-client__header___34IT8{margin-bottom:50px;margin-top:10px
}
.header-client__header__in___2lqSG{align-items:center;display:flex;justify-content:space-between
}
.header-client__header__profile___3iVB3{background:#1784cd;color:#fff;padding:30px 50px;width:24%
}
.header-client__header__profile--green___2d0OJ{background:#5bcc3e
}
.header-client__header__profile-name___8GnEW{display:block;font-weight:700;margin-bottom:5px
}
.header-client__header__profile-name___8GnEW:hover{text-decoration:underline
}
.header-client__header__profile-info___1m119{font-size:.8em;margin-bottom:2px
}
.header-client__header__col___2IUiN{border-top:10px solid #34aadc;padding:10px 20px;text-align:center;width:15%
}
.header-client__header__col-number___1wHW4{color:#1784cd;display:block;font-size:2.6em
}
.header-client__header__col-desc___hEnEK{color:grey;margin-top:5px
}