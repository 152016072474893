
.archive-item__tr___UT8eK{position:relative;transition:all .5s
}
.archive-item__tr--act___3iDmb{background:#ceecff
}
.archive-item__tr--doc-confirm___8xJX7{background:#e3ffe2
}
.archive-item__tr__td___1gKFs{border-bottom:1px solid rgba(0,0,0,.1);padding:10px 15px
}
.archive-item__tr__td___1gKFs:last-child{text-align:right
}
.archive-item__tr__loader___T86oZ{background:hsla(0,0%,100%,.7);bottom:0;left:0;position:absolute;right:0;top:0
}
.archive-item__tr__hint___1Z7Ya{color:#a9a9a9;font-size:.83em;margin:3px 0
}