
.noUi-horizontal{height:10px;margin:10px 3px
}
.noUi-horizontal .noUi-handle{height:21px;width:21px
}
.noUi-horizontal .noUi-tooltip{bottom:auto;top:120%
}
.noUi-handle:after,.noUi-handle:before{display:none
}
.noUi-connect{background:#34aadc
}